import React, { Component } from 'react'
import {AuthUserContext} from '../Session'
import { withFirebase } from '../Firebase'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import './Footer.css'
import './FooterRes.css'

class Footer extends Component {
    PopupWindow = () =>{
    const url = 'http://www.ftc.go.kr/www/bizCommView.do?key=232&apv_perm_no=2020323026130202155&pageUnit=10&searchCnd=bup_nm&searchKrwd=%EB%8D%94%EC%97%90%EC%9D%B4%EC%B9%98%EC%95%8C%EB%8D%94&pageIndex=1'
    window.open(url,"popup", "width=700, height=900, left=30, top=30, titlebar=no,status=no,fullscreen=no");
    }
    render() {
        
        return (
            <React.Fragment>
            <div className="footerPadding"></div>
            <div className="footerBg">
                <div className="footerFrame">
                    <div className="footerTitle">
                        <a href="#">XR BOOK<span>어린 왕자, 나를 만나다</span></a>
                    </div>
                    <div className="footerCont">
                        <div className="footerRight">
                            <p className="missionText">
                                Most Innovative HRD Company THE HRDer입니다.<br/>
                                HRD에 대한 열정과 사람의 가능성에 대한 무한한 믿음, 조직의 성과와 비전 달성을 위해<br/>
                                고민하는 모든 HRDer, 그 성장과 발전에 기여하겠습니다.
                            </p>
                            <p className="copyText">Copyright 2020. THE HRDer㈜ all rights reserved</p>
                        </div>
                        <div className="footerLeft">
                            <ul className="utilityBx">
                            <AuthUserContext.Consumer>
                                {authUser => (authUser?
                                <li><Link to='' onClick={()=> this.props.firebase.doSignOut()} className='noDeco'>로그아웃</Link></li>
                                :<li><Link to={ROUTES.LOGIN} className='noDeco'>로그인</Link></li>)}
                            </AuthUserContext.Consumer>
                                <li><Link to={ROUTES.TERMS} className='noDeco'>이용약관</Link></li>
                                <li><Link to={ROUTES.POLICIES} className='noDeco'>개인정보 처리방침</Link></li>
                                <li><span onClick={this.PopupWindow}>사업자정보확인</span></li>
                            </ul>
                            <div className="corpTextBx">
                                <ul>
                                    <li><span>뉴클(주)</span></li>
                                    <li><span><strong>대표이사</strong> : 김병수</span></li>
                                    <li><span><strong>사업자등록번호</strong> : 101-86-48709</span></li>
                                    <li><span><strong>통신판매신고번호</strong> : 제2020-서울송파-2155호</span></li>
                                </ul>
                                <ul>
                                    <li><span><strong>주소</strong> : 서울시 송파구 송파대로 201 613호 (문정동, 테라타워2 B동)</span></li>
                                </ul>
                                <ul>
                                    <li><span><strong>대표 전화</strong> : 1599-1432</span></li>
                                    <li><span><strong>팩스</strong> : 02-6008-9461</span></li>
                                    <li><span><strong>이메일</strong> : help@newkl.net</span></li>
                                </ul>
                                <ul>
                                    <li><span><strong>개인정보관리책임자</strong> : 박동열 (dr.park@newkl.net)</span></li>
                                    <li><span><strong>호스팅 제공</strong> : 가비아/Firebase </span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}
export default withFirebase(Footer)